import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function TooltipAllSmPreview(props) {
  const {
    TooltipSmSizeTop,
    TooltipSmSizeRight,
    TooltipSmSizeBottom,
    TooltipSmSizeLeft,
  } = props

  return (
    <>
      {/*      <!-- Component: All sm sized tooltips --> */}
      <p className="flex flex-col flex-wrap items-center justify-center gap-10 text-slate-700 sm:flex-row">
        {/*        <!-- Component: Top tooltip --> */}
        <CopyComponent
          copyToClipboardCode={TooltipSmSizeTop}
          componentName="TooltipSmSizeTop"
        >
          <span
            className="relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none"
            aria-describedby="tooltip-05"
          >
            {/*          <!-- Tooltip trigger --> */}
            top
            {/*          <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-48 -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              Thanks for hovering! I'm a top tooltip
            </span>
          </span>
        </CopyComponent>
        {/*        <!-- End Top tooltip --> */}

        {/*        <!-- Component: Right tooltip --> */}
        <CopyComponent
          copyToClipboardCode={TooltipSmSizeRight}
          componentName="TooltipSmSizeRight"
        >
          <span
            className="relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none"
            aria-describedby="tooltip-07"
          >
            {/*          <!-- Tooltip trigger --> */}
            right
            {/*          <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-07"
              className="invisible absolute left-full top-1/2 z-10 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-10 before:ml-2 before:-mt-1 before:border-y-4 before:border-r-4 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              Thanks for hovering! I'm a right tooltip
            </span>
          </span>
        </CopyComponent>
        {/*        <!-- End Right tooltip --> */}

        {/*        <!-- Component: Bottom tooltip --> */}
        <CopyComponent
          copyToClipboardCode={TooltipSmSizeBottom}
          componentName="TooltipSmSizeBottom"
        >
          <span
            className="relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none"
            aria-describedby="tooltip-06"
          >
            {/*          <!-- Tooltip trigger --> */}
            bottom
            {/*          <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-06"
              className="invisible absolute top-full left-1/2 z-10 mt-2 w-48 -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:bottom-full before:z-10 before:mt-2 before:-ml-1 before:border-x-4 before:border-b-4 before:border-x-transparent before:border-b-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              Thanks for hovering! I'm a bottom tooltip
            </span>
          </span>
        </CopyComponent>
        {/*        <!-- End Bottom tooltip --> */}

        {/*        <!-- Component: Left tooltip --> */}
        <CopyComponent
          copyToClipboardCode={TooltipSmSizeLeft}
          componentName="TooltipSmSizeLeft"
        >
          <span
            className="relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none"
            aria-describedby="tooltip-08"
          >
            {/*          <!-- Tooltip trigger --> */}
            left
            {/*          <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-08"
              className="invisible absolute right-full top-1/2 z-10 mr-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:left-full before:z-10 before:mr-2 before:-mt-1 before:border-y-4 before:border-l-4 before:border-y-transparent before:border-l-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              Thanks for hovering! I'm a left tooltip
            </span>
          </span>
        </CopyComponent>
        {/*        <!-- End Left tooltip --> */}
      </p>
      {/*      <!-- End All sm sized tooltips --> */}
    </>
  )
}
