import React from "react"
export default function HeroTooltipComponent() {
  return (
    <>
      {/*      <!-- Component: All base sized tooltips --> */}
      <p className="flex flex-col flex-wrap items-center justify-center gap-10 text-slate-700 sm:flex-row">
        {/*        <!-- Component: Top tooltip --> */}
        <span
          className="group relative cursor-pointer overflow-hidden hover:overflow-visible focus-visible:outline-none"
          aria-describedby="tooltip-01"
        >
          {/*          <!-- Tooltip trigger --> */}
          top
          {/*          <!-- End Tooltip trigger --> */}
          <span
            role="tooltip"
            id="tooltip-01"
            className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-48 -translate-x-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-2 before:border-x-8 before:border-t-8 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Thanks for hovering! I'm a top tooltip
          </span>
        </span>
        {/*        <!-- End Top tooltip --> */}

        {/*        <!-- Component: Right tooltip --> */}
        <span
          className="group relative cursor-pointer overflow-hidden hover:overflow-visible focus-visible:outline-none"
          aria-describedby="tooltip-03"
        >
          {/*          <!-- Tooltip trigger --> */}
          right
          {/*          <!-- End Tooltip trigger --> */}
          <span
            role="tooltip"
            id="tooltip-03"
            className="invisible absolute left-full top-1/2 z-10 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-10 before:ml-2 before:-mt-2 before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Thanks for hovering! I'm a right tooltip
          </span>
        </span>
        {/*        <!-- End Right tooltip --> */}

        {/*        <!-- Component: Bottom tooltip --> */}
        <span
          className="group relative cursor-pointer overflow-hidden hover:overflow-visible focus-visible:outline-none"
          aria-describedby="tooltip-02"
        >
          {/*          <!-- Tooltip trigger --> */}
          bottom
          {/*          <!-- End Tooltip trigger --> */}
          <span
            role="tooltip"
            id="tooltip-02"
            className="invisible absolute top-full left-1/2 z-10 mt-2 w-48 -translate-x-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:bottom-full before:z-10 before:mt-2 before:-ml-2 before:border-x-8 before:border-b-8 before:border-x-transparent before:border-b-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Thanks for hovering! I'm a bottom tooltip
          </span>
        </span>
        {/*        <!-- End Bottom tooltip --> */}

        {/*        <!-- Component: Left tooltip --> */}
        <span
          className="group relative cursor-pointer overflow-hidden hover:overflow-visible focus-visible:outline-none"
          aria-describedby="tooltip-04"
        >
          {/*          <!-- Tooltip trigger --> */}
          left
          {/*          <!-- End Tooltip trigger --> */}
          <span
            role="tooltip"
            id="tooltip-04"
            className="invisible absolute right-full top-1/2 z-10 mr-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:left-full before:z-10 before:mr-2 before:-mt-2 before:border-y-8 before:border-l-8 before:border-y-transparent before:border-l-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Thanks for hovering! I'm a left tooltip
          </span>
        </span>
        {/*        <!-- End Left tooltip --> */}
      </p>
      {/*      <!-- End All base sized tooltips --> */}
    </>
  )
}
