import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import TooltipsBaseSizePreview from "../../../library/components/tooltip/react/_preview/base"
import TooltipsSmSizePreview from "../../../library/components/tooltip/react/_preview/sm"
import HeroTooltipComponent from "../../../library/components/tooltip/react/_preview/heroTooltipComponent"

// *** Tooltips Base *** //
const TooltipBaseSizeTopJsx = require("!!raw-loader!../../../library/components/tooltip/react/base/top.jsx")
const TooltipBaseSizeTopHTML = require("!!raw-loader!../../../library/components/tooltip/html/base/top.html")

const TooltipBaseSizeRightJsx = require("!!raw-loader!../../../library/components/tooltip/react/base/right.jsx")
const TooltipBaseSizeRightHTML = require("!!raw-loader!../../../library/components/tooltip/html/base/right.html")

const TooltipBaseSizeBottomJsx = require("!!raw-loader!../../../library/components/tooltip/react/base/bottom.jsx")
const TooltipBaseSizeBottomHTML = require("!!raw-loader!../../../library/components/tooltip/html/base/bottom.html")

const TooltipBaseSizeLeftJsx = require("!!raw-loader!../../../library/components/tooltip/react/base/left.jsx")
const TooltipBaseSizeLeftHTML = require("!!raw-loader!../../../library/components/tooltip/html/base/left.html")

//*** Tooltips Sm *** //
const TooltipSmSizeTopJsx = require("!!raw-loader!../../../library/components/tooltip/react/sm/top.jsx")
const TooltipSmSizeTopHTML = require("!!raw-loader!../../../library/components/tooltip/html/sm/top.html")

const TooltipSmSizeRightJsx = require("!!raw-loader!../../../library/components/tooltip/react/sm/right.jsx")
const TooltipSmSizeRightHTML = require("!!raw-loader!../../../library/components/tooltip/html/sm/right.html")

const TooltipSmSizeBottomJsx = require("!!raw-loader!../../../library/components/tooltip/react/sm/bottom.jsx")
const TooltipSmSizeBottomHTML = require("!!raw-loader!../../../library/components/tooltip/html/sm/bottom.html")

const TooltipSmSizeLeftJsx = require("!!raw-loader!../../../library/components/tooltip/react/sm/left.jsx")
const TooltipSmSizeLeftHTML = require("!!raw-loader!../../../library/components/tooltip/html/sm/left.html")

export default function TooltipsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "TooltipsBaseSize",
      title: "Base sized tooltips",
      active_tab: 1,
    },
    {
      component_name: "TooltipsSmSize",
      title: "Small Sized",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Tooltips - WindUI Component Library"
        ogtitle="Tailwind CSS Tooltips - WindUI Component Library"
        description="Tooltip components are popups that display information related to an element when the mouse hovers over it. Built with Tailwind CSS by WindUI."
        ogdescription="Tooltip components are popups that display information related to an element when the mouse hovers over it. Built with Tailwind CSS by WindUI."
        url="components/tooltips/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Tooltips, Tooltips, Tooltip Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Tooltip</h1>
          <p>
            A tooltip is a popup that displays information related to an element
            when the mouse hovers over it. It after a small delay and disappears
            on the mouse out.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <HeroTooltipComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Tooltips Base Size */}
          <h3 id="TooltipsBaseSize">Base Sized Tooltip</h3>
          <p>The base size tooltip uses bigger text and internal padding.</p>

          <PreviewBlock
            id="TooltipsBaseSize"
            HtmlComponent={TooltipBaseSizeTopHTML.default}
            JsxComponent={TooltipBaseSizeTopJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <TooltipsBaseSizePreview
                TooltipBaseSizeTop={
                  activeTabs[0].active_tab === 1
                    ? TooltipBaseSizeTopHTML.default
                    : TooltipBaseSizeTopJsx.default
                }
                TooltipBaseSizeRight={
                  activeTabs[0].active_tab === 1
                    ? TooltipBaseSizeRightHTML.default
                    : TooltipBaseSizeRightJsx.default
                }
                TooltipBaseSizeBottom={
                  activeTabs[0].active_tab === 1
                    ? TooltipBaseSizeBottomHTML.default
                    : TooltipBaseSizeBottomJsx.default
                }
                TooltipBaseSizeLeft={
                  activeTabs[0].active_tab === 1
                    ? TooltipBaseSizeLeftHTML.default
                    : TooltipBaseSizeLeftJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Tooltips Sm Size */}
          <h3 id="TooltipsSmSize">Small Sized Tooltip</h3>
          <p>The small size tooltip uses smaller text and internal padding.</p>

          <PreviewBlock
            id="TooltipsSmSize"
            HtmlComponent={TooltipSmSizeTopHTML.default}
            JsxComponent={TooltipSmSizeTopJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <TooltipsSmSizePreview
                TooltipSmSizeTop={
                  activeTabs[1].active_tab === 1
                    ? TooltipSmSizeTopHTML.default
                    : TooltipSmSizeTopJsx.default
                }
                TooltipSmSizeRight={
                  activeTabs[1].active_tab === 1
                    ? TooltipSmSizeRightHTML.default
                    : TooltipSmSizeRightJsx.default
                }
                TooltipSmSizeBottom={
                  activeTabs[1].active_tab === 1
                    ? TooltipSmSizeBottomHTML.default
                    : TooltipSmSizeBottomJsx.default
                }
                TooltipSmSizeLeft={
                  activeTabs[1].active_tab === 1
                    ? TooltipSmSizeLeftHTML.default
                    : TooltipSmSizeLeftJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            When visible, tooltips display a text label identifying an element,
            such as a description of its function. A tooltip is displayed upon
            hovering (on desktop) or tapping the screen (mobile) on the element
            or component.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Tooltip can be visible in all four directions: <strong>top</strong>,
            <strong>right</strong>, <strong>bottom</strong> and
            <strong> left</strong>. It comes in two sizes, base and small with
            differences in font size and internal padding.
          </p>
          <ul>
            <li>
              <strong>base:</strong> font-size 0.875rem (14px) and padding 1rem
              (16px).
            </li>
            <li>
              <strong>sm:</strong> font-size 0.75rem (12px) and padding 0.5rem
              (8px).
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              A tooltip should have the arial role of
              <code>role=&quot;tooltip&quot;</code>
              and a unique
              <strong> id</strong>.{" "}
            </li>
            <li>
              The element that triggers the tooltip should have the{" "}
              <code>aria-describedby=&quot;id&quot;</code> attribute point to
              the tooltip's id.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
